.barra{
	background-color: #007648;
	width: 100%;
	height: auto;
}

.nombre_cliente{
  text-align: center !important;
  color: white;
  margin-left: -50px;
}

.nombre_cliente h3{
    margin-top: 8px !important;
    margin-bottom: 0px !important;
}

.barra div{
	padding: 1em 0em;
	text-align: right;
}

.barra div a{
	color: white;
	font-size: 1.5em;
	text-decoration: none;
	padding: 10px 1.5em;
}

.barra div a:hover{
	color: #00CDFF;
}

div.logo_img {
	text-align: center;
}

div.links{
	display: flex;
}

div.links>div{
	width: 50%;
}

div.links div.image {
	padding: 10px 1.5em;
	text-align: left;
}

.menuHeader {
  position: relative;
}

.menuHeader button {
  position: absolute;
  right: 40px;
  top: 10px;
  z-index: 10;
  cursor: pointer;
  background: transparent;;
  border: none; 
}

.menuNav {
  padding: inherit;
  margin-top: 0px;
  overflow-y: scroll;
  list-style: none;
  position: fixed;
  top: 0;
  background: #007648;
  right: 0;
  bottom: 0;
  height: 20vh;
  width: 0;
  overflow: hidden;
  max-width: 200px;
  z-index: 9;
}

.menuNav.showMenu {
  width: 100%;
}

div.menuHeader>a {
  display: block;
  padding: 10px 40px;
  text-decoration: none;
  color: #3fffd2;
  text-transform: uppercase;
  font-weight: bold;
}

.menuNav li:first-child {
  margin-top: 7rem;
}