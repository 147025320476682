.main_cargas{
  margin-top: 2em;
}

.container_cargas{
  display: flex;
  justify-content: space-around;  
  text-align: center;
  margin-top: 1em;
}

.container_cargas>div>div{
  justify-content: center;
  flex-wrap: wrap;
  width: 25%;
}


.volver_cargas {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  padding: 1em 1em;
  text-align: left;
}

.volver_cargas img{
  cursor: pointer;
}

.volver_cargas img:hover {
  transform: scale(1.2);
}