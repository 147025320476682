.content{
  justify-content: center;
  display: flex;
}

.main {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2em;
  background-color: #EFEFEF;
  padding-top: 20pt;
  padding-left: 20pt; 
  padding-right: 20pt;
  padding-bottom: 25pt;
}

input.logout{
  width: 50%;
  float: right;
}

.main>input{
  border-radius: 10px;
  width: 30%;
  background-color: #1B8253; 
  border: none;
  color: white;
  padding: 3px 3px;
  cursor: pointer;
}

.main h2{
  color: #568a6e;
}