.inter_div_cargas{
	background-color: #f2f2f2;
	padding-top: 1.5em;
}

.inter_div_cargas a{
	color: #568a6e;
	text-decoration: none;
}

.container_cargas h1{
	background-color: #f2f2f2;
	color: #568a6e;
	padding: 2em 2em;
}

@media screen and (min-width: 425px){
	.inter_div_cargas{
		margin-top: 2em;
		padding: 1em 1em;
	}
}