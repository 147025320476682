.container_form_register{
  text-align: center;
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
}

.register_header_status{
  color: #568a6e;
  font-weight: bold;
  padding: 1.5em 2em; 
  background-color: #f2f2f2;
}

.form_register_header{
  font-weight: bold;
  padding: 1.5em 2em; 
  background-color: #f2f2f2; 
}

.form_register_header h2{
  color: #568a6e;
  font-weight: bold;
  text-align: left;
  padding-left: 2em;
}

.container_form_new_user{
  margin-top: 2em;
  background-color: #f2f2f2;
  padding: 3em 5em;
}

.container_form_new_user>div{
  display: flex;
  flex-wrap: wrap;
}

.container_form_new_user>div>div:nth-child(1){
  width: 10%;
}

.container_form_new_user>div>div:nth-child(2){
  width: 90%;
}

.container_form_new_user input{
  margin-top: 2%;
  float: left;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}

input[type="email"]::placeholder { 
      text-align: center;
}

.form_register_header_selector{
  margin-top: 2em;
  background-color: #f2f2f2;
  padding: 1.5em 2em; 
}

.form_register_header_selector h2{
  color: #568a6e;
  font-weight: bold;
  padding: 0em 2em;
  text-align: left;
  padding-left: 2em;
}

.border_selectores_cargas{
  background-color: #f2f2f2;
  padding: 1.5em 10px;
  padding-left: 10px;
  text-align: center;
}

.selector_registro_cargas{
  max-height: 250px;
  min-height: 250px;
  overflow-y: scroll;
  flex-wrap: wrap;
  display: flex;
}

.selector_registro_cargas>div{
  width: 33.33%;
}

.identificador_cargas{
  background-color: white;
  margin: .5em;
  width: 90%;
  padding: .5em .5em;
  margin-left: 5%;
  margin-right: 5%;
  cursor: pointer;
}

.identificador_cargas:hover{
  background-color: #DDE8E2;
}

.border_selectores_cargas{
  background-color: #f2f2f2;
  padding: 1.5em 10px;
  text-align: center;
}

.selecionado_registro_cargas{
  max-height: 250px;
  min-height: 250px;
  overflow-y: scroll;
  flex-wrap: wrap;
  display: flex;
}

.selecionado_registro_cargas>div{
  width: 33.33%;
}

.identificador_cargas_seleccionado{
  background-color: #DDE8E2;
  margin: .5em;
  width: 90%;
  padding: .5em .5em;
  margin-left: 5%;
  margin-right: 5%;
  cursor: pointer;
}

.identificador_cargas_seleccionado:hover{
  background-color: white;
}

.registrar_boton{
  text-align: right;
}

.registrar_boton input{
  width: 20%;
  border-radius: 10px;
  background-color: #78a18b; 
  border: none;
  color: white;
  padding: 10px 10px;;
  cursor: pointer;
}

/*Editar usuario*/
.container_users_list{
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  background-color: #f2f2f2;
  padding: 1em 1em;
  margin-bottom: 2em;
}

.container_users_list>div:nth-child(2n+1){
  background-color: white;
}

.container_users_list>div:nth-child(2n){
  background-color: #f1f6f3;
}

.container_users_list>div:nth-child(1){
  background-color: unset;
}

.container_users_list_inside{
  display: flex;
  margin: 15px 15px;
  padding: .5em .5em;
}

.container_users_list_inside>div:nth-child(1),.header_container_users_list_inside>div:nth-child(1){
  width: 40%;
}

.container_users_list_inside>div:nth-child(2),.header_container_users_list_inside>div:nth-child(2){
  width: 20%;
}

.container_users_list_inside>div:nth-child(3),.header_container_users_list_inside>div:nth-child(3){
  width: 30%;
}

.container_users_list_inside>div:nth-child(4),.header_container_users_list_inside>div:nth-child(4){
  width: 10%;
}


.header_container_users_list_inside{
  display: flex;
  margin: 15px 15px;
  padding: .5em .5em;
  font-weight: bold;
}

.container_button_new_user{
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  padding: 1em 0em;
  text-align: right;
}

#button_new_user{
  padding: 1em 1em;
  border-radius: 10px;
  font-size: 15px;
  background-color: #007648;
  color: white;
  border: none;
  cursor: pointer;
}

.sub_header_edit h3{
  color: #568a6e;
}

.boton_reset{
  text-align: center;
}

.reset{
  background-color: #78a18b; 
  border: none;
  color: white;
  padding: 20px 20px;
  cursor: pointer;
  border-radius: 10px; 
}