table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}

td, th {
  border: 0px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(1) {
  background-color: #A6A6A6 !important;
}

tr:nth-child(even) {
  background-color: #f1f6f3;
}

tr:nth-child(odd) {
  background-color: #fff;
}

.volver_mediciones , .descargar_mediciones , .enviar_mediciones{
  margin-left: 5em;
  padding: 1em 1em;
  text-align: center;
}

.volver_mediciones img:hover , .descargar_mediciones img:hover , .enviar_mediciones img:hover {
  transform: scale(1.2);
}

.volver_mediciones img, .descargar_mediciones img, .enviar_mediciones img{
  cursor: pointer;
  background-color: #fff;
  padding: .2em 1em;
  border-radius: 10px;
}

.botones_mediciones{
  display: flex;

}

.container_mediciones_resultado{
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  background-color: #f1f6f3;
  padding-bottom: 1em;
  padding-top: 1.5em;
}

.container_mediciones_header{
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  background-color: #f1f6f3;
  padding: 0.1em 0em;
}

.container_mediciones_header h2{
  padding-left: 4em;
  color: #568a6e !important;
  font-weight: bold;
}

.container_mediciones_parametros{
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  display: flex;
}

.container_mediciones_header_tipo, .container_mediciones_header_fecha{
  background-color: #f2f2f2;
  padding: 0.1em 5em;
}

.container_mediciones_header_tipo h2, .container_mediciones_header_fecha h2{
  color: #568a6e !important;
  font-weight: bold;
}

.container_mediciones_parametros>div{
  width: 49%;
}

.container_mediciones_parametros>div:nth-child(1){
  margin-right: 1%;
  justify-content: left;
  
}

.container_mediciones_parametros>div:nth-child(2){
  margin-left: 1%;
  justify-content: left;
}

.fecha_resultado_seleccionadas{
  background-color: #f2f2f2;
  padding: 1.5em;
}

.fecha_resultado_seleccionadas>div{
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}

.tipo_resultado_seleccionado{
  background-color: #f2f2f2;
  height: 81%; 
  text-align: center;
}

.tipo_resultado_seleccionado h2{
  padding-top: 5em;
}

.container_mediciones_medidores{
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  background-color: #f2f2f2;
  padding-bottom: 1.5em;
}

.container_mediciones_medidores h2{
  color: #568a6e !important;
  font-weight: bold;
  padding: 1em 3em;
}

.container_show_medidores{
  display: flex;
  flex-wrap: wrap;
  max-height: 150px;
  overflow-y:  scroll;
  padding-bottom: 1.5em;
}

.container_show_medidores>div{
  width: 31.33%;
  margin-left: 1%;
  margin-right: 1%;
  padding-bottom: 1em;
}

.show_medidores{
  background-color: #DDE8E2;
  padding: 1em 1em;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.rdrDayDisabled{
  background-color: transparent !important; 
}

.rdrDateDisplayItem{
  background-color: transparent !important;
}

.noinfo{
  text-align: center;
  padding-bottom: 6em;
}

.noinfo img{
  height: 200px;
  width: 200px;
}

.header_tabla{
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}

.header_tabla h2{
  color: #568a6e !important;
}

.volver_mediciones_up {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  padding: 1em 1em;
  text-align: left;
}

.volver_mediciones_up img{
  cursor: pointer;
}

.volver_mediciones_up img:hover {
  transform: scale(1.2);
}

.container_disclaimer{
  padding: 1em 1em;
  background-color: #f2f2f2;
  width: 88.5%;
  margin-left: 5%;
  margin-right: 5%;
}

.container_range_dates{
  display: flex;
  justify-content: space-around;
  width: 90%;
  text-align: center;
  margin-left: 5%;
  margin-right: 5%;
}

.container_range_dates>div{
  width: 100%;
  background-color: #f2f2f2;
}

.container_range_dates>div:nth-child(1){
  margin-right: 1%;
}

.container_range_dates>div:nth-child(2){
  margin-left: 1%;
}

.container_missing_dates{
  width: 85%;
  margin-left: 5%;
  background-color: #f2f2f2;
  padding: 1em 0em;
  padding-left: 6em;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 1em;
}

.container_missing_dates h3{
  margin: 0.5em 0em;
  color: #568a6e;
}

.container_missing_dates>div:nth-child(1){
  width: 100%;
  margin-bottom: 1em; 
}

.container_missing_dates>div{
  width: 10%;
  margin-bottom: 1em; 
}