.container_menu{
  text-align: center;
  margin-top: 30px;
  background-color: #EFEFEF;
  padding: 1em 1em;
  border-radius: 10px;
  cursor: pointer;
}

.main_menu{
  display: flex;
  justify-content: center;
}

.container_buttons_menu{
  text-align: center;
  margin-top: 1em;
}

.container_buttons_menu>div>div{
  justify-content: center;
  display: flex;
  width: 100%;
}

@media screen and (min-width: 425px){
  .container_buttons_menu{
    display: flex;
    flex-wrap: wrap; 
  }

  .container_buttons_menu>div{
    width: 100%;
  }

  .main_menu{
    display: flex;
  }
}

@media screen and (min-width: 900px){
  .container_buttons_menu{
    display: flex;
    justify-content: space-around;  
  }

  .container_buttons_menu>div{
    width: 25%; /*33.33*/
  }

  .main_menu{
    display: block;
  }
}