@import url("https://cdn.syncfusion.com/ej2/material.css");

.identificador_medidores{
	height: max-content;
	text-align: center;
	background-color: #fff;
	width: 90%;
	margin-left: 5%;
	min-height: 45px;
	cursor: pointer;
	margin: 0px 0px;
	padding-top: 15px;
}

.identificador_medidores:hover{
	background-color: #DDE8E2;
}

.identificador_medidores_seleccionado{
	height: max-content;
	text-align: center;
	background-color: #DDE8E2;
	width: 90%;
	margin-left: 5%;
	min-height: 45px;
	cursor: pointer;
	margin: 0px 0px;
	padding-top: 15px;
}

.identificador_medidores_seleccionado:hover{
	background-color: white;
}

.main_container_medidores{
	margin-top: 1em;
	width: 80%;
	margin-left: 10%;
	margin-right: 10%;
	margin-bottom: 1em;
	overflow-y: visible;
}

.main_container_medidores h3{
	font-style: Robot;
	font: bold;
	color: #568a6e;
	padding-left: 1.5em;
}

.titles_medidores{
	background-color: #f2f2f2;
	padding: .5em 1em;
}

.selector_fechas_medidores,.selector_medidores_medidores,.selecionado_medidores_medidores,.selector_tipo_medidores{
	background-color: #f2f2f2;
}

.border_selectores_medidores{
	background-color: #f2f2f2;
	padding: 20px 20px;
}


.selector_fechas_medidores{
	display: flex;
	padding: 1em 1em;
}

.selector_fechas_medidores>div{
	width: 100%;
}

 .selector_fechas_medidores>div>img{
   padding: 35px 0;
  text-align: center;
 }

input[type="text"].input_medidores{
	background-color: #f2f2f2;
  	border: none;
  	padding: 1em 1em;
  	margin-left: 10px;
}

input:focus{
    outline: none;
}

input[type="text"].input_medidores::placeholder { 
      text-align: left;
    }

.search_bar_icon{
	display: flex;
}

.selector_medidores_medidores{
	max-height: 200px;
	min-height: 100px;
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 1em;
	overflow-y: scroll;
}

.selector_medidores_medidores>div{
	width: 33.33%;
	margin-bottom: 1em;
}

.selecionado_medidores_medidores>div{
	width: 33.33%;
	margin-bottom: 1em;
}

.selecionado_medidores_medidores{
	min-height: 200px;
	max-height: 200px;
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 1em;
	overflow-y: scroll;
}

 .selector_tipo_medidores{
 	display: flex;
 	text-align: center;
 	justify-content: space-between;
 }
 .selector_tipo_medidores>div{
 	width: 25%;
 }

 .inter_selector_tipo{
 	display: flex;
 	justify-content: center;

 }
 .inter_selector_tipo>div{
 	width: 50%;
 	padding: 10px 0px;
 }
 .inter_selector_tipo>div>label{
 	padding: 10px 10px;
 }

.rdrCalendarWrapper, .rdrDateRangeWrapper{
	display: grid !important;
}

.rdrMonth{
	width: 90% !important;
	margin-left: 5% !important;
}

.rdrDayDisabled{
	background-color: transparent !important;	
}

.rdrDateDisplayItem{
	background-color: transparent !important;
}

span.rdrStartEdge.rdrEndEdge {
	color: #568a6e !important;
}

span.rdrInRange , span.rdrStartEdge , span.rdrEndEdge{
	color: #568a6e !important;
}

#minutal , #horaria, #suma{
	padding: 0px 0px;
	width: 100%;
	cursor: pointer;
}

.inter_selector_tipo>div>div>button , .container_suma_medidores>div>button{
	padding: 0px 0px;
	margin: 0px 0px;
}

.selected{
	background-color: #DDE8E2;
}

.no-selected{
	background-color: none;
}

.container_button_peticion{
	text-align: right;
}

.button_solicitar_medicion{
	border: none;
	padding: 1em 1em;
	background-color: #78a18b;
	color: white;
	border-radius: 10px;
	box-shadow: none !important;
	border-color: none !important;
	cursor: pointer;
}

.error_medidores{
	text-align: center;
	background-color: rgba(240, 54, 54 ,1);
}

.volver_medidores {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  padding: 1em 1em;
  text-align: left;
}

.volver_medidores img{
	cursor: pointer;
}

.volver_medidores img:hover {
  transform: scale(1.2);
}

.error_medidores{
	width: 30%;
	margin: 0% 35%;
	padding: 1.5em 0em;
	background-color: #f2f2f2;
	text-align: center;
}

.error_medidores h2{
  color: #568a6e !important;
  font-weight: bold;
}

.container_suma_medidores{
	display: flex;
	padding-left: 1.5em;
}

.container_suma_medidores>div>h3{
	padding-top: .7em;
}
