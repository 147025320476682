.container_not_loggin{
	background-color: #EFEFEF;
	margin-top: 2em;
	padding: 2em 2em;
	text-align: center;
	width: 60%;
	margin-left: 20%;
	margin-right: 20%;
	border-radius: 10px;
}

.container_not_loggin h1 {
	color: #568a6e;
}
