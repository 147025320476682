.title_page{
  margin-top: 4em;
  text-align: center;
  color: #1B8253;
  font-family: Robot;
  font: bold;
  background-color: #f2f2f2;
  margin-bottom: -1em;
}

.error{
  text-align: center;
  color: red;
}

.content{
  justify-content: center;
  display: grid !important;
}

.main {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2em;
  background-color: #f2f2f2;
  padding-top: 20pt;
  padding-left: 20pt; 
  padding-right: 20pt;
  padding-bottom: 25pt;
}

.inputs{
  display: flex;
  justify-content: center;
  align-items:center;
  padding-top: 8px;
}


form.login_form>div>div:nth-of-type(1){
  width: 20%;
}

form.login_form>div>div:nth-of-type(2){
  width: 80%;
}

div.inputs>div:nth-of-type(2){
  width: 100%;
}

div.inputs>div>input{
  width: 80%;
  background-color: #f2f2f2;
  border: none;
  float: right;
}

input.login{
  width: 60%;
  background-color: #78a18b; 
  border: none;
  color: white;
  padding: 20px 20px;
  cursor: pointer;
  border-radius: 10px;
}

hr {
  height:2px;
  border-width:0;
  color:#568a6e;
  background-color:#568a6e;
}

input[type="text"]::placeholder { 
      text-align: center;
    }

input[type="password"]::placeholder { 
      text-align: center;
    }

input:focus{
    outline: none;
}

div.boton_login{
  text-align: center;
}

.link_reset{
  margin-top: 1em;
  background-color: #f2f2f2;
  text-align: center;
}

.link_reset a{
  text-decoration: none;
  color: #1B8253;
}

.volver_login {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  padding: 1em 1em;
  text-align: left;
}

.volver_login img{
  cursor: pointer;
}

.volver_login img:hover {
  transform: scale(1.2);
}

.reset_form{
  width: 40%;
  margin-left: 30%;
  margin-left: 30%;
  margin-top: 2em;
  background-color: #f2f2f2;
  padding-top: 20pt;
  padding-left: 20pt; 
  padding-right: 20pt;
  padding-bottom: 25pt;  
}

.container_reset_divs{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.container_reset_divs>div:nth-of-type(1){
  width: 10%;
}

.container_reset_divs>div:nth-of-type(2){
  width: 89%;
}

#Correo_reset{
  margin-top: 1.5em;
  width: 100%;
}